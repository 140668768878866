body {
  background-color: white;
  margin: 0;
  max-width: 100vw;
  min-height: 100vh;
  font-family: Verdana, Geneva, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  color: #464343;
  font-size: 2em;
}

h2 {
  color: #702670;
  font-size: 1.2em;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
ul,
li,
span,
button {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  border-bottom: 2px solid transparent;
  transition: all 0.3s ease-in;
  padding: 3px 0;
}
a:hover {
  border-bottom: 2px solid white;
}

p {
  margin-bottom: 15px;
}

ul {
  list-style: none;
}

.img-link {
  border-bottom: none;
  padding: 0px;
}
.img-link:hover {
  border-bottom: none;
  padding: 0px;
}

.img-link img {
  transition: all 0.2s ease-in;
}

/* ---- PRESETS ---- */
.btn {
  padding: 10px 20px;
  margin: 30px auto;
  border-radius: 30px;
  border: none;
  color: white;
  font-size: 1.2em;
  cursor: pointer;
  transition: all 0.2s ease-in;
  outline: none;
}
.btn:hover {
  cursor: pointer;
}

.btn-sm {
  font-size: 1em;
  padding: 5px 10px;
  margin: 0;
}

.btn-success {
  background-color: #1cae1c;
}

.btn-success:hover {
  background-color: #19c219;
}

.btn-danger {
  background-color: #c51d1d;
}
.btn-danger:hover {
  background-color: #e21f1f;
}

.btn-theme {
  border-radius: 0px;
  border: 2px solid #f5f5f5;
}

.btn-light {
  background-color: white;
  color: #292727;
}
.btn-light:hover {
  background-color: #fffdfd;
}

.btn-soft {
  background-color: #955656;
}
.btn-soft:hover {
  background-color: #744646;
}

.btn-dark {
  background-color: #292727;
}
.btn-dark:hover {
  background-color: #1f1c1c;
}

.btn-selected {
  background-color: #2a3edb;
}
.btn-selected:hover {
  background-color: #2234b8;
}

.cookie {
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  font-size: 0.8em;
  border: 2px solid #d4caca;
}
.cookie span {
  color: grey;
}
